var generic = generic || {};
var site = site || {};
site.rewardsSortBy = site.rewardsSortBy || '';
site.isRewardAdded = site.isRewardAdded || false;
var settings = settings || Drupal.settings;

site.isPointsExpireEnable = site.isPointsExpireEnable || '';

(function ($) {
  'use strict';

  var $body = $('body');
  var bp = {};
  var isMobileWidth = true;
  var animationTime = 100;
  var isAvedaFlagshipApp = site.isAvedaFlagshipApp;
  var hideHeaderOnScroll = false;
  var siteHeaderHeight = 0;
  var topSiteHeaderHeight = 0;
  var contentBlockSitewideBannerHeight = 0;
  var position = $(window).scrollTop();

  $(document).on('contentBlock.sitewideBanner', function (e, bannerHeight, headerHeight) {
    var height = $body.hasClass('site-header-formatter--sticky') ? 0 : bannerHeight;

    siteHeaderHeight = headerHeight;
    contentBlockSitewideBannerHeight = height;
    $(window).trigger('layout.adjustContentPosition', [headerHeight]);
  });

  Drupal.behaviors.siteHeaderV1 = {
    elems: {},
    active: false,
    $activeCategory: false,
    menuAnimationInterval: false,
    options: {
      enableHover: true,
      // Our buffer for when we mouseenter between overlay / menu
      // This is for a smoother user experience
      overlayHoverCloseDelay: 400
    },
    attach: function (context) {
      var self = this;
      var $module = $('.js-site-header--v1', context);
      var page_title = document.title;
      var $isHome = $('.homepage-wrapper.js-basic-formatter', context);
      var $chatBlock = $('.js-header-chat-link', context);
      var $sitewideBanner = $('.js-site-header-content-block-sitewide-banner', context);
      hideHeaderOnScroll = $module.hasClass('hide-site-header-on-scroll');

      if (!$module.length) {
        return;
      }
      $chatBlock.once().on('click', function (e) {
        e.preventDefault();
        $('[data-lp-event]', context).trigger('click');
      });
      site.isPointsExpireEnable = $module.attr('data-pp-points-expire') || '';
      site.rewardsSortBy = $module.attr('data-pp-sort-by') || '';
      siteHeaderHeight = $module.find('.js-site-header-main').outerHeight(true) || 0;
      topSiteHeaderHeight = $sitewideBanner.length ? $sitewideBanner.outerHeight(true) : 0;

      // Flagship App Detection helper class, see: js/site/app_detect.js
      if (isAvedaFlagshipApp) {
        $('body', context).addClass('flagship-app');
      }

      if ($isHome.length) {
        $('body', context).addClass('home-page');
      }
      self.elems.$header = $module;
      self.elems.$globalOverlay = $('.js-content-dim', context);
      self.elems.$menu = $('.js-nav-menu', self.elems.$header);
      self.elems.$hamburger = $('.js-nav-launch', self.elems.$header);
      self.elems.$close = $('.js-nav-close', self.elems.$header);
      self.elems.$closeSearch = $('.js-search-nav-close', self.elems.$header);
      self.elems.$categoryTrigger = $('.js-nav-category-trigger', self.elems.$header).not(
        '.js-subnav .menu__subnav .js-nav-category-trigger'
      );
      self.elems.$linkTrigger = $('.js-nav-link-trigger', self.elems.$header).not(
        '.menu__content .js-nav-link-trigger'
      );
      self.elems.$userLoyalty = $('.js-user-loyalty', self.elems.$header);
      self.elems.$headermenu = $('.site-header__menu,.site-header__wrapper');
      self.elems.$offerMenu = self.elems.$header.find('.site-header__offer');

      // Links that trigger dropdowns / overlays -- search, sign in dropdown
      self.elems.$contentWrapper = $('.js-nav-content-dropdown', self.elems.$header);
      self.elems.$contentTriggers = $('.js-nav-content,.guest-content', self.elems.$header);
      self.elems.$contentBlocks = $('.js-nav-content-block', self.elems.$header);
      // Added h1 tag with title for purpose of SEO
      self.elems.$sitelogodiv = $('.site-header__menu-logo', self.elems.$header);
      self.elems.$sitelogodiv.before('<h1 class="page-title hidden">' + page_title + '</h1>');
      self.elems.gnavUpdatesEnabled = self.elems.$header.hasClass('js-gnav-updates--enabled');
      self.elems.$menuItemCategory = $('.js-menu-item-category', self.elems.$header);
      // Start listening
      self.loyaltyApiStatus(context);
      self.updateResponsive();
      self.attachEventListeners();
      self.attachContentListeners();
      self.updateLoyalty();
      self.hasRewardItem(context);
      self.setBackLink(context);

      if (!self.elems.$offerMenu.height()) {
        self.elems.$header.addClass('no-offer');
      }

      var container = $('.js-language-container', context);

      if (container.length) {
        if (container.children('.js-lang-link1').length) {
          container.children('.js-lang-link1').attr('href', container.data('link1-url'));
        }
        if (container.children('.js-lang-link2').length) {
          container.children('.js-lang-link2').attr('href', container.data('link2-url'));
        }
      }

      function _updateNavStatus() {
        var scroll = $(window).scrollTop();
        var templateHeight =
          $module.find('.js-site-header-main').outerHeight(true) + contentBlockSitewideBannerHeight;

        // If the user scroll down, hide the Nav
        if (scroll <= position) {
          $body.toggleClass('site-header-formatter--hidden', false);
        } else if (scroll > 5) {
          $body.toggleClass('site-header-formatter--hidden', true);
          $body.toggleClass('site-header-formatter--sticky', true);
        }

        if (scroll <= templateHeight) {
          $body.toggleClass('site-header-formatter--sticky', false);
        }

        position = scroll;
      }

      if (hideHeaderOnScroll) {
        $(window)
          .off('scroll.gnavHeaderBlock')
          .on('scroll.gnavHeaderBlock', _.throttle(_updateNavStatus, 100));
        _updateNavStatus();
      }

      $module
        .find('.js-site-header-main')
        .off('transitionrun.siteHeaderV1')
        .on('transitionrun.siteHeaderV1', function () {
          var height;

          if ($body.hasClass('site-header-formatter--sticky') && $body.hasClass('site-header-formatter--hidden')) {
            height = 0;
          } else if (
            contentBlockSitewideBannerHeight > 0 &&
            ($body.hasClass('site-header-formatter--sticky') ||
            $body.hasClass('content-block-sitewide-banner--hidden')
            )) {
            height = siteHeaderHeight - topSiteHeaderHeight;
          } else {
            height = siteHeaderHeight;
          }

          $(window).trigger('layout.adjustContentPosition', [height]);
        });
    },

    // This function adjusts the variables on window resize
    updateResponsive: function () {
      var self = this;
      var newBreakpoint = Unison.fetch.now().name;

      // Close everything if we have a new breakpoint
      if (newBreakpoint !== bp) {
        bp = newBreakpoint;
        isMobileWidth = bp === 'small';
        self.elems.$header.trigger('gnav.close');
        self.closeContent();
      }
      // Determine what the top menu level is depending on screen size
      self.elems.$topMenuLevel = (function () {
        // On mobile detect when clicking outside the header
        if (isMobileWidth) {
          return self.elems.$menu.find('.site-header__menu-wrapper');
        } else {
          return self.elems.$header;
        }
      })();

      // Stop listening to our scroll event
      $(window).off('scroll.gnav');

      // On any resize, redo this function
      $(window).one(
        'resize.gnav',
        _.debounce(function () {
          // Refresh our variables
          self.updateResponsive();

          // show scrollbars on short windows/screens
          if (self.elems.$contentWrapper.is(':visible')) {
            var windowHeight = $(window).height();

            self.elems.$contentWrapper.css({
              maxHeight: windowHeight - 170,
              overflow: 'auto'
            });
            self.elems.$contentWrapper[0].scrollTop = 0;
          }
        }, 150)
      );
    },

    attachEventListeners: function () {
      var self = this;

      // Global Events
      self.elems.$header.off('gnav.open').on('gnav.open', function () {
        self.active = true;
        self.elems.$menu.addClass('active');
        self.closeContent();
        self.elems.$globalOverlay.addClass('active');
        if (isMobileWidth) {
          self.elems.$headermenu.addClass('menu-position');
          $('html, body').addClass('no-scroll');
        }
        self.toggleViewMore(true);
        // Avoid triggering clickoutside with the same click event that opens the gnav
        setTimeout(function () {
          // Only listen to clickoutside when menu is open
          self.elems.$topMenuLevel.one('clickoutside.menu', function () {
            self.elems.$header.trigger('gnav.close');
          });
        }, 200);
        // Hide Drupal admin trigger
        $('#toolbar-trigger').hide();
      });

      self.elems.$header.off('gnav.close').on('gnav.close', function () {
        var $lastMenuElement = $('.js-menu-item-category.menu__item--lvl-1.active', self.elems.$header);
        self.active = false;
        // Close all active categories
        self.closeMenus();
        self.elems.$contentWrapper.stop(true, true).slideUp(animationTime);
        self.closeContent();
        // Close the gnav itself
        self.elems.$menu.removeClass('active');
        self.elems.$contentTriggers.removeClass('.active');
        self.elems.$headermenu.removeClass('menu-position');
        self.elems.$topMenuLevel.off('clickoutside.menu');
        $('html,body').removeClass('no-scroll');
        self.toggleViewMore(false);
        // Show Drupal admin trigger
        $('#toolbar-trigger').show();
        self.elems.$globalOverlay.removeClass('active');
        if ($lastMenuElement.length > 0) {
          $lastMenuElement.focus();
        } else {
          $('.js-gnav-util-trigger--cart', self.elems.$header).focus();
        }
      });

      // Click Events
      self.elems.$hamburger.off('click.open-gnav').on('click.open-gnav', function (event) {
        event.preventDefault();
        self.elems.$header.trigger('gnav.open');
      });

      self.elems.$close.off('click.close-gnav').on('click.close-gnav', function (event) {
        event.preventDefault();
        self.elems.$header.trigger('gnav.close');
        self.elems.$contentTriggers.attr('aria-expanded', 'false');
      });

      self.elems.$closeSearch.off('click.close-gnav').on('click.close-gnav', function (e) {
        e.preventDefault();
        self.elems.$header.trigger('gnav.close');
        self.elems.$contentTriggers.removeClass('active');
        self.elems.$contentTriggers.attr('aria-expanded', 'false').focus();
      });

      self.elems.$linkTrigger.off('mouseout').on('mouseout', function (event) {
        if (self.elems.gnavUpdatesEnabled) {
          event.preventDefault();
          self.elems.$header.trigger('gnav.open');
          self.elems.$globalOverlay.removeClass('active');
        }
      });

      self.elems.$linkTrigger.off('mouseenter').on('mouseenter', function (event) {
        event.preventDefault();
        if (!self.checkHoverEventIsValid(event)) {
          return;
        }
        if (self.elems.gnavUpdatesEnabled) {
          if (self.$activeCategory) {
            self.$activeCategory.parents('.js-nav-category-trigger-content').addClass('nav-hidden');
            self.$activeCategory.addClass('nav-hidden');
            self.$activeCategory.parents('.js-menu-item-category').removeClass('active');
            self.elems.$categoryTrigger.attr('aria-expanded', 'false');
          }
          self.elems.$globalOverlay.removeClass('active');
          self.elems.$menuItemCategory.addClass('no-active');
          $(this).parent('li').addClass('active').removeClass('no-active');
        }
        else {
          self.elems.$header.trigger('gnav.close');
        }
      });
      self.elems.$categoryTrigger
        .off('keydown.open-category')
        .on('keydown.open-category', function (e) {
          if (site.getKeycode(e) === 13) {
            e.preventDefault();
            e.stopPropagation();
            $(this).trigger('mouseover');
          }
          var $currentElement = $(this);

          if (e.shiftKey) {
            $currentElement.attr('aria-expanded', 'false').trigger('mouseout');
            $currentElement.parent().removeClass('active');
            $currentElement.siblings().addClass('nav-hidden');
            $('.js-content-dim').trigger('overlay.closed').removeClass('active');
          }
        });
      self.elems.$categoryTrigger
        .off('click.open-category mouseenter.open-category')
        .on('click.open-category mouseenter.open-category', function (event) {
          event.preventDefault();
          // Redirect when clicked main menu for PC
          var redirectUrls = $(this).attr('href');

          if (
            event.type === 'click' &&
            !isMobileWidth &&
            redirectUrls != '' &&
            redirectUrls != '#'
          ) {
            window.location.href = redirectUrls;
          }
          // Do some validation to see if we should continue
          if (!self.checkHoverEventIsValid(event)) {
            return;
          }
          var $elem = $(this).siblings('.js-nav-category-trigger-content');

          // Determine if the element is already open
          if (event.type === 'mouseenter' && !isMobileWidth) {
            var isOpen = !$elem.hasClass('nav-hidden');

            if (isOpen) {
              return;
            }
          }
          if (isMobileWidth && $(this).parent('li').is('.menu__item--lvl-1.active')) {
            self.closeMenus();

            return;
          }
          // If we're opening a category on desktop, make sure any queued menu animation is cleared
          if (event.type === 'mouseenter') {
            // Set our closing animation
            clearTimeout(self.menuAnimationInterval);
          }
          // Break our closing animation
          // Open the category and pass this specific $categoryTrigger
          self.toggleCategory($(this));
          if (!$(this).siblings('.js-nav-category-trigger-content').hasClass('nav-hidden')) {
            $(this).attr('aria-expanded', 'true');
          }
          // There is a subnav here and this is a desktop interaction
          if ($(this).parent().find('.js-subnav').length) {
            self.attachSubnav($(this).parent().find('.js-subnav'));
          }
        });

      self.elems.$globalOverlay
        .off('click.close-overlay mouseenter.close-overlay focusin.close-overlay')
        .on('click.close-overlay mouseenter.close-overlay focusin.close-overlay', function (event) {
          event.preventDefault();
          var closeOverlay = function () {
            self.elems.$header.trigger('gnav.close');
            self.closeContent();
            // Custom event for listeners
            self.elems.$globalOverlay.trigger('overlay.closed');
          };

          // Do some validation to see if we should continue
          if (!self.checkHoverEventIsValid(event)) {
            return;
          }
          // Mouseenter on desktop has a delayed animation, the click is right away
          if ((event.type === 'mouseenter' || event.type === 'focusin') && !isMobileWidth) {
            // We're going to close the overlay, but first if any of these elements are hovered over before the menu can close,
            // don't close the menu. A user might move their mouse to the overlay and then bring the mouse back to the menu.
            var $cancelOverlayCloseElems = $(
              '.js-nav-category-trigger-content, .js-nav-content-dropdown',
              self.elems.$header
            );

            $cancelOverlayCloseElems
              .off('mouseenter.cancel-close-overlay focusin.cancel-close-overlay')
              .one(
                'mouseenter.cancel-close-overlay focusin.cancel-close-overlay',
                function (event) {
                  clearTimeout(self.menuAnimationInterval);
                }
              );
            // Set our closing animation
            self.menuAnimationInterval = setTimeout(
              closeOverlay,
              self.options.overlayHoverCloseDelay
            );
          } else {
            closeOverlay();
          }
        });
    },

    // Our dropdown content interaction
    attachContentListeners: function () {
      var self = this;
      var disableGnav = Drupal.settings.disableGnav ? Drupal.settings.disableGnav : false;
      var returnUrl = window.location.pathname;
      var signinUrl = '/account/signin.tmpl';

      // On click of a trigger in the header, show the content in the dropdown
      self.elems.$contentTriggers
        .off('click.content-trigger mouseenter.content-trigger')
        .on('click.content-trigger mouseenter.content-trigger', function (event) {
          event.preventDefault();
          // Do some validation to see if we should continue
          if (!self.checkHoverEventIsValid(event)) {
            return;
          }
          // On desktop mouseenter, if this trigger has data-hover="true", open it on hover
          // Otherwise do nothing on mouseenter for desktop
          if (event.type === 'mouseenter' && !isMobileWidth) {
            if ($(this).data('hover') !== true) {
              return;
              // If this is already active, and this is mouseenter on desktop only and this trigger has a hover interaction, prevent anything from happening
            } else if ($(this).hasClass('active')) {
              return;
            }
          }

          // If this is already open, close this
          if ($(this).hasClass('active')) {
            $(this).attr('aria-expanded', 'false');
            self.closeContent();

            return;
          }
          var contentSection = $(this).data('content');

          // Close the gnav
          self.elems.$header.trigger('gnav.close');
          // Get which content to load from this trigger
          // Hide all the blocks inside the content wrapper
          self.elems.$contentBlocks.addClass('hidden');
          self.elems.$contentBlocks
            .filter('[data-content="' + contentSection + '"]')
            .removeClass('hidden');
          self.elems.$contentWrapper.attr('data-nav-dropdown-block', contentSection);
          // Show this one
          if (
            settings.sign_in_redirection &&
            $(this).parent('.site-header__nav-right').length > 0 &&
            $(this).parent('.site-header__utility-right').length > 0
          ) {
            window.location = signinUrl;
          } else if (disableGnav && contentSection === 'signin') {
            window.location =
              returnUrl === '/' || returnUrl === signinUrl
                ? signinUrl
                : signinUrl + '?return_url=' + returnUrl;
          } else if (!disableGnav && isMobileWidth && contentSection === 'signin') {
            window.location = signinUrl;
          } else {
            $(this).addClass('active');
            $(this).attr('aria-expanded', 'true');
            $(window).trigger('resize.gnav');
            self.elems.$contentWrapper
              .stop(true, true)
              .slideDown(isMobileWidth ? 500 : animationTime);
            self.elems.$contentWrapper.focus();
            site.restrict_navigation(self.elems.$contentWrapper);
            self.elems.$globalOverlay.addClass('active');
            if ($('.js-signin-block').is(':visible')) {
              $('.js-signin-block').focus();
              site.restrict_navigation($('.js-signin-block'));
            } else if ($('.js-end-esearch-nav').is(':visible')) {
              $('.js-predictive-search-input').focus();
              site.restrict_navigation($('.js-end-esearch-nav'));
            }
          }
        });

      self.elems.$contentTriggers.focus(function (e) {
        var $lastMenuElement = $('.js-menu-item-category.active');

        $lastMenuElement.find('> .js-nav-category-trigger').attr('aria-expanded', 'false');
        $lastMenuElement.find('> .js-nav-category-trigger-content').addClass('nav-hidden');
        $lastMenuElement.removeClass('active');
        $('.js-content-dim').trigger('overlay.closed').removeClass('active');
      });

      self.elems.$contentTriggers.keydown(function (e) {
        if (site.getKeycode(e) === 13) {
          e.preventDefault();
          $(this).attr('aria-expanded', 'true');
          $(this).trigger('click');
          $('.js-end-esearch-nav').focus();
        }
      });
      $(document).trigger('gnav.events.attached');
    },

    // Check if the hover option is enabled and we're not on mobile
    checkHoverEventIsValid: function (event) {
      var self = this;

      // Check that its NOT a mouseenter event where enableHover is turned off or we're on mobile
      return !(event.type === 'mouseenter' && (!self.options.enableHover || isMobileWidth));
    },

    // On user load, handle our loyalty display
    updateLoyalty: function () {
      var self = this;

      // set first name
      $(document).on('user.loaded', function (e, user) {
        if (!!user.first_name) {
          self.elems.$userLoyalty.find('.js-pp-firstname').text(user.first_name);
        }

        if (!!user.pure_priv_num) {
          generic.jsonrpc.fetch({
            method: 'pure_privilege.getMemberInfo',
            params: [
              {
                force: 1,
                pp_number: user.pure_priv_num
              }
            ],
            onSuccess: function (jsonRpcResponse) {
              var pp = jsonRpcResponse.getValue();

              $(document).trigger('pure_privilege.loaded', [pp]);
            }
          });
        }
      });

      $(document).on('pure_privilege.loaded', function (e, pp) {
        console.log('pure_privilege.loaded');

        // set active class to toggle join link visibility
        self.elems.$userLoyalty.addClass('active');

        if (!!pp) {
          // set points in header
          if (!!pp.pointBalanceFormatted || pp.pointBalanceFormatted === 0) {
            var $points = self.elems.$userLoyalty.find('.js-pp-points');
            var pointsSuffix = $points.data('points-suffix') || 'points';

            $points.text(pp.pointBalanceFormatted + ' ' + pointsSuffix);
          }
        }
      });
    },

    // Check the BOND API status
    loyaltyApiStatus: function (context) {
      generic.jsonrpc.fetch({
        method: 'pure_privilege.getVersionStatus',
        onSuccess: function (response) {
          var apiStatus = response.getValue().status;
          var $siteHeader = $('.js-site-header', context);
          var message = $siteHeader.attr('data-pp-down-msg') || '';
          var disableSku = $siteHeader.data('pp-sku-disabled');
          var $joinNow = $('.user-loyalty__points-join', context);

          $siteHeader.attr('data-pp-status', apiStatus);
          if (apiStatus === 1) {
            $(document).trigger('api.available');
          }
          if (apiStatus === 0 && message !== '') {
            $('.js-rewards-sample', context)
              .find(
                'a[href="/pure-privilege/rewards"], aside[data-clickable="/pure-privilege/rewards"]'
              )
              .addClass('js-pp-down_popup');
            var $ppDown = $('.js-pp-down_popup', context);

            $('.js-account-index', context).prepend(
              '<ul class="error_messages error"><li>' + message + '</li></ul>'
            );
            $('li[id*=pure_priv]', context).addClass('hidden');
            $joinNow.removeClass('js-add-to-cart');
            $joinNow
              .add($ppDown)
              .off('click')
              .on('click', function (e) {
                e.preventDefault();
                generic.overlay.launch({
                  content: message,
                  className: 'pure_privilege_overlay'
                });
              });
            $(document).trigger('api.unavailable', message);
          } else if (apiStatus === 1 && disableSku === true) {
            var enrollment_disabled_msg = $siteHeader.data('pp-sku-disabled-msg') || '';

            $joinNow.removeClass('js-add-to-cart');
            $joinNow.off('click').on('click', function (e) {
              e.preventDefault();
              generic.overlay.launch({
                content: enrollment_disabled_msg,
                className: 'pure_privilege_overlay'
              });
            });
          }
        },
        onError: function () {
          // Error
        }
      });
    },

    // Checking Aveda plus reward is already in the cart.
    hasRewardItem: function (context) {
      var $joinNow = $('.user-loyalty__points-join', context);

      if ($joinNow.length === 0) {
        return;
      }

      $(document).on('addToCart.success', function (e, results) {
        if (
          !_.isUndefined(results) &&
          !_.isUndefined(results.ac_results) &&
          !_.isUndefined(results.ac_results[0]) &&
          !_.isUndefined(results.ac_results[0].result)
        ) {
          if (
            (!_.isUndefined(results.ac_results[0].result.CARTITEM) &&
              !_.isUndefined(results.ac_results[0].result.CARTITEM.isPurePrivilegeEnrollmentFee)) ||
            !_.isUndefined(results.ac_results[0].result.CARTITEM.isPPEnrollmentFee)
          ) {
            if (
              results.ac_results[0].result.CARTITEM.isPurePrivilegeEnrollmentFee === 1 ||
              results.ac_results[0].result.CARTITEM.isPPEnrollmentFee === 1
            ) {
              $(document).trigger('membership.incart');
              site.isRewardAdded = true;
            }
          }
        }
      });

      $(document).on('membership.incart', function () {
        $joinNow.removeClass('js-add-to-cart');
        $joinNow.off('click').on('click', function (e) {
          e.preventDefault();
          var $siteHeader = $('.js-site-header', context);
          var alreadyInCartmsg = $siteHeader.data('pp-already-incart') || '';

          generic.overlay.launch({
            content: alreadyInCartmsg,
            className: 'colorbox--system-message',
            cssStyle: {
              width: '350px',
              height: 'auto'
            }
          });
        });
      });

      generic.jsonrpc.fetch({
        method: 'trans.get',
        params: [
          {
            order_fields: ['items']
          }
        ],
        onSuccess: function (d) {
          var cartResponse = d.getValue();

          if (typeof cartResponse === 'undefined' || !cartResponse) {
            return;
          }
          var cartItems = cartResponse.order.items;

          cartItems.forEach(function (el) {
            if (el.isPurePrivilegeEnrollmentFee === 1 || el.isPPEnrollmentFee === 1) {
              $(document).trigger('membership.incart');
            }
          });
        },
        onError: function () {}
      });
    },

    // Close all of our content interaction
    closeContent: function () {
      var self = this;

      self.elems.$contentTriggers.removeClass('active');
      self.elems.$contentWrapper.stop(true, true).slideUp(animationTime);
      self.elems.$contentBlocks.addClass('hidden');
      self.elems.$contentWrapper.removeAttr('data-nav-dropdown-block');
      self.elems.$globalOverlay.removeClass('active');
    },

    // This function slides down the category once a primary category is clicked
    toggleCategory: function ($category) {
      var self = this;
      // .js-nav-category-trigger-content is right next to category element
      var $elem = $category.siblings('.js-nav-category-trigger-content');
      // Determine if the element is already open
      var isOpen = !$elem.hasClass('nav-hidden');

      // If an existing element is open, close it and its parent
      // This will close the category that is open, including this category
      // However make sure that the clicked category is not a subcategory of the existing activeCategory
      if (self.$activeCategory !== false && !$.contains(self.$activeCategory[0], $category[0])) {
        self.$activeCategory.addClass('nav-hidden');
        self.$activeCategory.siblings().attr('aria-expanded', 'false');

        // If the opening category is deeper than a top level category, only close the children within this
        if ($category.parents('li').length > 1) {
          self.$activeCategory.closest('li').removeClass('active');
          self.$activeCategory
            .closest('li')
            .find('.js-nav-category-trigger-content')
            .addClass('nav-hidden');
          self.$activeCategory
            .closest('li')
            .find('.js-nav-category-trigger-content')
            .siblings()
            .attr('aria-expanded', 'false');
        } else {
          // When switching between two top level categories, just close everything
          self.closeMenus();
          self.elems.$globalOverlay.removeClass('active');
        }
        self.$activeCategory = false;
      }
      // If wasn't open already, open it now
      if (!isOpen) {
        if (isMobileWidth) {
          self.closeMenus();
        }
        self.$activeCategory = $elem;
        if (self.elems.gnavUpdatesEnabled) {
          self.elems.$menuItemCategory.addClass('no-active');
          self.$activeCategory.removeClass('nav-hidden').parents('li').addClass('active');
          self.$activeCategory.removeClass('nav-hidden').parents('li').removeClass('no-active');
        }
        else {
          self.$activeCategory.removeClass('nav-hidden').parents('li').addClass('active');
        }
        // The block above adds .hidden to .js-nav-category-trigger-content
        // If the menus are siblings, this causes the parent .js-nav-category-trigger-content to be hidden
        // This forces all parent .js-nav-category-trigger-content to not be hidden
        self.$activeCategory.parents('.js-nav-category-trigger-content').removeClass('nav-hidden');
        // On desktop, our first category click triggers the gnav to open
        if (!isMobileWidth) {
          self.elems.$header.trigger('gnav.open');
        } else {
          if (!self.elems.gnavUpdatesEnabled) {
            self.$activeCategory
              .closest('li')
              .find('.js-nav-category-trigger-content .js-nav-category-trigger')
              .first()
              .trigger('click');
          }
        }
      }
    },

    attachSubnav: function ($subnav) {
      var $subnavLinks = $('.menu__subnav a', $subnav);
      var $subnavSections = $subnav.parent().find('.js-subsection');
      var $menu_item_lvl_3 = $('.menu__item--lvl-3 > a');

      // Ignores empty menu item links
      $menu_item_lvl_3.each(function () {
        if ($(this).attr('class') === '') {
          $(this).attr('tabindex', '-1');
        }
      });

      // Quick toggle for the subnav
      $subnavLinks.off('click.subnav').on('click.subnav', function (event) {
        event.preventDefault();
        $(this).parent().addClass('active');
        // We're reusing regular menu items, so don't make them trigger
        // the default menu item click handler
        event.stopPropagation();
        var index = $(this).parent().index();

        $subnavLinks.attr({
          'aria-expanded': 'false',
          tabindex: '-1'
        });
        $(this).attr('tabindex', '0');
        // When a subnav link is clicked, toggle the other ones
        $subnavLinks.each(function () {
          $(this).parent().removeClass('active');
        });
        $(this).parent().addClass('active');
        if ($(this).parent().hasClass('active')) {
          $(this).attr('aria-expanded', 'true');
        }
        // Show this subnav section
        $subnavSections.hide();
        $subnavSections.eq(index).fadeIn(animationTime);
      });

      $subnavLinks.focusout(function (e) {
        var lastSubMenuSection = $('.js-menu_subnav > li:last > a');
        var $this = $(this);
        var isActive = $this.parent().hasClass('active');
        var targetElement = $('.menu__item--lvl-1.active').next().children('a');

        if ($this.text() === lastSubMenuSection.text() && !isActive) {
          targetElement.focus().trigger('mouseover');
        }
      });

      $('.js-menu-item-tout').on('focusout', function () {
        var length = $(this).closest('.js-subnav').length;
        var $this = $('.menu__item--lvl-1.active').next().children('a');
        var $nextElement = $(this).parent().next();

        if (length > 0) {
          $('.js-menu_subnav > li > .js-nav-category-trigger').removeAttr('tabindex');
          $('.js-menu_subnav > li.active > a').focus();
          var subMenu = $('.header-primary-menu').find('.js-subnav > .js-menu_subnav > li:last');

          if (subMenu.hasClass('active')) {
            $this.focus().trigger('mouseover');
          }
        } else {
          if (
            $nextElement.hasClass('js-menu-item-tout') ||
            $nextElement.hasClass('menu__item--tout')
          ) {
            $nextElement.find('a').focus();
          } else {
            $this.focus().trigger('mouseover');
          }
        }
      });

      // Click our first one by default
      $subnavLinks.eq(0).trigger('click.subnav');
    },

    // Set the header back link
    setBackLink: function () {
      var self = this;
      var $backButton = $('.js-mobile-checkout-back, .js-checkout-back', self.elems.$header);

      $backButton.once().on('click', function (e) {
        e.preventDefault();
        history.back();
      });
    },

    // This closes all menu items and hides their content including subnav and subsections
    closeMenus: function () {
      var self = this;
      var content = self.elems.$menu.find('.js-nav-category-trigger-content');

      // Close everything
      self.elems.$menu.find('li').removeClass('active');
      if (self.elems.gnavUpdatesEnabled) {
        self.elems.$menu.find('li').removeClass('no-active');
      }
      // Find all parent li elements and hide their content
      content.addClass('nav-hidden');
      content.siblings().attr('aria-expanded', 'false');
      // Remove any subnavs if any
      self.elems.$header.find('.js-subnav, .js-subsection').removeClass('active');
    },

    // Desktop interaction
    toggleViewMore: function (enable) {
      var self = this;
      // Prep our variables
      var cutoff = parseInt(self.elems.$header.data('cutoff'));
      var cutoffLabel = self.elems.$header.data('cutoff-label')
        ? self.elems.$header.data('cutoff-label')
        : 'View More';

      if (!cutoff) {
        return;
      }
      // We are only applying this to the menu items inside the subnav
      var $items = $('.js-subsection .menu--lvl-3', self.elems.$header);

      $('.js-view-more-wrapper', $items).remove();
      // Do our splicing
      if (enable) {
        $items.each(function () {
          // @TODO -- Clean this up
          // This removes any menu items that are empty before doing the slicing
          $(this)
            .find('.menu__item--lvl-3')
            .each(function () {
              if ($(this).find('a').text() === '') {
                $(this).remove();
              }
            });

          // If we have enough menu items for our cutoff, do it and add the View More trigger
          if ($(this).find('.menu__item--lvl-3').length > cutoff) {
            $(this).find('.menu__item--lvl-3').slice(cutoff).hide();
            // @TODO -- Move this into Mustache perhaps
            var $viewMore = $(
              '<li class="menu__item menu__item--view-more menu__item--lvl-3 menu__item--category js-view-more-wrapper"><a class="js-view-more" href="#">' +
                cutoffLabel +
                '</a></li>'
            );

            $(this).append($viewMore);
          }
        });
      }
      $items.off('click.view-more').on('click.view-more', '.js-view-more', function (event) {
        event.preventDefault();
        // Show this column
        var $column = $(this).closest('.menu--lvl-3');

        $column.find('.js-view-more').remove();
        $column.find('.menu__item--lvl-3').slideDown(300);
        // @TODO -- This click event triggers clickoutside.menu for some reason
        // Figure out why and remove the event.stopPropagation()
        event.stopPropagation();
      });
    }
  };
})(jQuery);
